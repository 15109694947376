import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
// import TransitionLink from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
// import { sweepEnterLength, sweepExitLength } from './constants';

const WipeLink = ({ children, to, className }) => {
  return (
    // needs a div wrapper because of gatsby rehydration issue, issue link: https://github.com/TylerBarnes/gatsby-plugin-transition-link/issues/248
    <div
      className={`${styles.linkWrapper} ${className}`}
      style={{ display: 'inline-block' }}
    >
      <AniLink
        className={`${styles.aniLink}`}
        cover
        style={{ textDoration: 'none' }}
        to={to}
        direction='left'
        bg={'#3e424f'}
        duration={2}
      >
        {children}
      </AniLink>
    </div>
  );
};

export default WipeLink;
